.icon-angle-down {
	width: 10px;
	height: 6.17px;
}

.icon-angle-left {
	width: 6.17px;
	height: 10px;
}

.icon-angle-right {
	width: 6.17px;
	height: 10px;
}

.icon-angle-up {
	width: 10px;
	height: 6.17px;
}

.icon-arrow-left {
	width: 16px;
	height: 16px;
}

.icon-collapse {
	width: 33.94px;
	height: 17.08px;
}

.icon-cross {
	width: 24px;
	height: 24px;
}

.icon-delete {
	width: 11.67px;
	height: 15px;
}

.icon-doc {
	width: 24px;
	height: 24px;
}

.icon-expand {
	width: 33.94px;
	height: 17.08px;
}

.icon-flag {
	width: 12.53px;
	height: 14.2px;
}

.icon-folder {
	width: 14.15px;
	height: 11.32px;
}

.icon-folder-download {
	width: 13.36px;
	height: 10.69px;
}

.icon-link {
	width: 20px;
	height: 10px;
}

.icon-menu {
	width: 97.29px;
	height: 92.3px;
}

.icon-note-add {
	width: 20px;
	height: 17.44px;
}

.icon-pencil {
	width: 15px;
	height: 15px;
}

.icon-play {
	width: 13.35px;
	height: 13.35px;
}

.icon-play-video {
	width: 38.8px;
	height: 49.38px;
}

.icon-plus-round {
	width: 24px;
	height: 24px;
}

.icon-search {
	width: 24px;
	height: 24px;
}

.icon-star {
	width: 24px;
	height: 24px;
}

.icon-twitter {
	width: 20px;
	height: 16.25px;
}

